import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyNordicNaturalsTemplate = ({ image }) => {
  return (
    <React.Fragment>
      {" "}
      <CaseStudyHeaderSection
        title="Nordic Naturals"
        description="A legacy supplement brand expands its reach on social platforms and marketplaces with Hook Points"
        image={image}
      />
      <CaseStudyDescriptionSection
        list={
          <ul>
            <li>
              Developed Hook Points to attract and increase audience while
              preparing the company for large consumer behavior shift from
              offline to online retail, and strengthened bonds and relationships
              with retail and distribution partners
            </li>
            <li>
              Leveraged Hook Points for internal communication between
              executives, employees and contractors to restructure
              organizational resources and maximize effort, which led to a
              dramatic increase in overall revenue
            </li>
            <li>
              Built an entire content studio in Los Angeles for Nordic Naturals
              to develop social Hook Point content to grow their brand, reach
              and revenue.
            </li>
            <li>
              Developed strong Hook Points to aid in conversation with current
              and future retail partners, including but not limited to
              Walgreens, CVS, Sprouts, Rite Aid, Target, Walmart. These Hook
              Points differentiated Nordic Naturals from competitors to win new
              retail and mass deals, and get better placement for their product
              on shelves.
            </li>
            <li>
              The brand’s Instagram page grew from 13,000 followers to over
              300,000 in a few short months. Created a new YouTube channel that
              generated 3.1 million views in 6 months. Previous YouTube channel
              generated 250,000 views in 11 years prior to Hook Points.
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-yahoo"}
        text="Explore our work with Nordic Naturals further. Apply to work with us"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyNordicNaturalsTemplate
