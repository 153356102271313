import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import CaseStudyNordicNaturalsTemplate from "../modules/caseStudy/CaseStudyNordicNaturals.template"

const CaseStudyNordicNaturals = ({ data }) => {
  return (
    <Layout>
      <SEO title="Case Study Nordic Naturals" />
      <CaseStudyNordicNaturalsTemplate
        image={data.caseStudyNordicNaturals.edges}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    caseStudyNordicNaturals: allFile(
      filter: { relativeDirectory: { eq: "caseStudyNordicNaturals" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CaseStudyNordicNaturals
